<!-- eslint-disable vue/no-mutating-props -->
<template>
  <section v-if="company">
    <CiHeading level="2" class="mb-2">
      {{ $t("settings.profile") }}
    </CiHeading>
    <VRdxInput
      v-model="company.name"
      :disabled="pending.load || pending.save"
      :label="$t('settings.company-name')"
      :name="$t('settings.company-name')"
      rules="required"
    />
    <RdxSelect
      :value="getTimezone(company.timezone)"
      :options="timezones"
      :disabled="pending.load || pending.save"
      :label="$t('settings.timezone')"
      :placeholder="$t('settings.select-timezone')"
      class="mt-4"
      vm-track-by="tz_identifier"
      :custom-label="
        option => {
          return `${option.label}`;
        }
      "
      :allow-empty="false"
      data-cy="timezone-select"
      @input="setTimezone"
    />
    <RdxSelect
      :value="companyLocale"
      :options="countries"
      :disabled="pending.load || pending.save"
      vm-label="name"
      vm-track-by="code"
      :label="$t('settings.locale')"
      :placeholder="$t('settings.select-locale')"
      :allow-empty="false"
      class="mb-4"
      @input="setLocale"
    >
      <template slot="singleLable" slot-scope="{ option }">
        {{ option }}
      </template>
    </RdxSelect>
    <RdxButton
      filled
      :disabled="pending.load || pending.save"
      @click="saveSettings"
    >
      {{ $t("global.save") }}
    </RdxButton>
    <CiHeading level="2" class="mt-5 mb-2">
      {{ $t("settings.advanced") }}
    </CiHeading>
    <RdxSelect
      v-model="selectedCompany"
      :options="companies"
      :custom-label="customLabel"
      :label="$t('settings.switch-account')"
      :disabled="pending.load || pending.save"
      track-by="attributes.uuid"
      :allow-empty="false"
      :placeholder="$t('settings.switch-account')"
    >
      <template slot="singleLabel">
        <span v-if="selectedCompany && selectedCompany.attributes">
          {{ selectedCompany.attributes.name }}
        </span>
      </template>
    </RdxSelect>
  </section>
</template>

<script>
import COUNTRIES from "./countries.json";

import { RdxSelect, RdxInput, RdxButton } from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";

import { customBtoa } from "@/store/base64";
import { withValidation } from "vee-validate";

const VRdxInput = withValidation(RdxInput, ({ errors }) => ({ errors }));

export default {
  name: "CarsInventorySettingsProfile",

  components: {
    RdxSelect,
    VRdxInput,
    RdxButton,
    CiHeading
  },

  props: {
    companies: {
      type: Array,
      default: () => []
    },
    selectedCompany: {
      type: Object,
      default: () => ({})
    },
    pending: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      timezones: [],
      countries: COUNTRIES,
      company: { ...this.selectedCompany.attributes }
    };
  },

  computed: {
    companyLocale() {
      return (
        this.countries.find(({ code }) => code === this.company.locale) || null
      );
    }
  },

  watch: {
    selectedCompany(newValue, oldValue) {
      this.company = { ...this.selectedCompany.attributes };
      if (oldValue && oldValue.attributes && newValue && newValue.attributes) {
        if (newValue.attributes.uuid !== oldValue.attributes.uuid) {
          window.localStorage.setItem(
            "company_uuid",
            customBtoa(newValue.attributes.uuid)
          );
          window.location.reload();
        }
      }
    }
  },

  created() {
    this.$http.get("/companies/time_zones").then(({ data }) => {
      this.timezones = Object.values(data.data).map(zone => zone.attributes);
    });
  },

  methods: {
    customLabel(option) {
      return option.attributes?.name;
    },
    setTimezone(timezone) {
      this.company.timezone = timezone ? timezone.name : null;
    },
    getTimezone(timezone_name) {
      return this.timezones.find(({ name }) => timezone_name === name);
    },
    setLocale(locale) {
      this.company.locale = locale ? locale.code : null;
    },
    saveSettings() {
      this.$emit("saveSettings", this.company);
    }
  }
};
</script>
